import {createBrowserHistory} from 'history';
window.axios = require('axios');
//window.axios.defaults.baseURL = 'http://inventariodev-env.eba-gktsnnxs.us-east-2.elasticbeanstalk.com';
//window.axios.defaults.baseURL = 'https://backoffice-api.ducker.monster';
window.axios.defaults.baseURL = 'https://dianashop-admin-api.kangoru.mx';
window.axios.interceptors.response.use(
    function (response) { 
        return response; 
    }, 
    function (error) { 
        if (401 === error.response.status) { 
            //window.location.replace('login');
           createBrowserHistory().push('/login');
           window.location.reload();
        }

	return Promise.reject(error);
    });

window.axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
export const axios = window.axios;
